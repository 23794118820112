.module-search {
  position: relative;
}

.module-search > .search-list {
  position: absolute;
  overflow-y: auto;
  right: 0;
  transform:translateY(55%);
  width:400px;
  max-height:550px;
  background-color: white;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  z-index: 1000;
}
.search-list > ul {
  width: 100%;
  padding: 0;
  margin: 0 0 auto 0;
}
.search-list > ul > li > a{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:2.5% 5%;
  height: fit-content !important;
  margin:0;
  align-items:normal !important;
  border-bottom: 1px solid #c4c4c4;
}
.search-list > ul > li:hover > a {
  background-color: #f4f4f4;
  cursor: pointer;
}
.search-list > ul > li > a > h5 {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin:0;
}
.search-list > ul > li > a > p {
  display: flex;
  flex-wrap: wrap;
  font-size:0.8rem;
  margin-top:6px;
  margin-bottom:0px;
}
.search-list > ul > li > a > p > span {
  display: block;
  margin-right:2px;
}

.search-list > ul > li > a> p > span:nth-child(even) {
  font-weight: 600;
  color: black
}



.load-more {
  padding:5px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #f1f1f1;
}