@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,600;1,200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wrapper{
  display: flex;
  /* background-color: rgba(26, 25, 25, 0.199); */
  
}
*{
    font-family: 'Poppins', sans-serif !important;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.react-contextmenu-wrapper{
    height:100%;
}
.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 100px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }
  .react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
  cursor: pointer;
}
.react-contextmenu-item{
    margin-top: "20px";
}


.module-search {
  position: relative;
}

.module-search > .search-list {
  position: absolute;
  overflow-y: auto;
  right: 0;
  -webkit-transform:translateY(55%);
          transform:translateY(55%);
  width:400px;
  max-height:550px;
  background-color: white;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  z-index: 1000;
}
.search-list > ul {
  width: 100%;
  padding: 0;
  margin: 0 0 auto 0;
}
.search-list > ul > li > a{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:2.5% 5%;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin:0;
  align-items:normal !important;
  border-bottom: 1px solid #c4c4c4;
}
.search-list > ul > li:hover > a {
  background-color: #f4f4f4;
  cursor: pointer;
}
.search-list > ul > li > a > h5 {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin:0;
}
.search-list > ul > li > a > p {
  display: flex;
  flex-wrap: wrap;
  font-size:0.8rem;
  margin-top:6px;
  margin-bottom:0px;
}
.search-list > ul > li > a > p > span {
  display: block;
  margin-right:2px;
}

.search-list > ul > li > a> p > span:nth-child(even) {
  font-weight: 600;
  color: black
}



.load-more {
  padding:5px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #f1f1f1;
}
.file {
  background-color: white;
  margin: 5px 0;
}

.file-upload-error {
  color: orangered;
  font-weight: lighter;
}

.file-upload-info {
  color: royalblue; 
  font-weight: lighter;
}

.file > span {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: capitalize;
}

.file > .file-details {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 0.9;
}

.file > .success {
  background-color: green;
  color: white;
}

.file > .file-details > p {
  margin: 0;
}

