.file {
  background-color: white;
  margin: 5px 0;
}

.file-upload-error {
  color: orangered;
  font-weight: lighter;
}

.file-upload-info {
  color: royalblue; 
  font-weight: lighter;
}

.file > span {
  display: block;
  width: fit-content;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: capitalize;
}

.file > .file-details {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 0.9;
}

.file > .success {
  background-color: green;
  color: white;
}

.file > .file-details > p {
  margin: 0;
}
